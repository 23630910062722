'use client';

import { useShallow } from 'zustand/react/shallow';
import experimentService from 'whoop-analytics/lib/sources/experiment';
import { useSiteStore } from '@/store/SiteStoreProvider';

export function useExperimentService() {
  const { experimentVariants, setExperimentVariant } = useSiteStore(
    useShallow((state) => ({
      experimentVariants: state.experimentVariants,
      setExperimentVariant: state.setExperimentVariant,
    })),
  );

  const getVariant = async (experimentName: string): Promise<string | null> => {
    let variant = experimentVariants[experimentName];
    if (!variant) {
      variant = await experimentService.getVariant(experimentName);
      setExperimentVariant(experimentName, variant);
    }

    return variant;
  };

  const getExistingVariant = (experimentName: string): string | undefined => {
    return experimentVariants[experimentName];
  };

  return {
    getVariant,
    getExistingVariant,
  };
}
