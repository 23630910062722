import {
  formatPriceWithSiteValues,
  type PriceFormatOptions,
} from '@whoop/i18n/utils/priceHelpers';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';

export interface SiteFormatValues {
  currency: Currency;
  language: Language;
  region: Region;
}

export const formatDisplayPrice = (
  price: number,
  siteValues: SiteFormatValues,
  formatOptions?: PriceFormatOptions,
): string => {
  return formatPriceWithSiteValues(price, siteValues, {
    ...formatOptions,
    showCents: false,
  });
};

export const formatPrice = (
  price: number,
  siteValues: SiteFormatValues,
  formatOptions?: PriceFormatOptions,
): string => {
  return formatPriceWithSiteValues(price, siteValues, {
    ...formatOptions,
  });
};
