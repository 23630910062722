'use client';
import React from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import type { Address, CartProduct, OrderTotal } from 'ui';
import { isTaxCharged } from 'ui';
import type { Currency, Language, Region } from '@whoop/i18n';
import {
  canApplyAdditionalTaxes,
  canPriceUpdateToIncludeVat,
} from '@whoop/i18n/utils/taxHelpers';
import type { AccessoryGroup } from 'services/generated/growth-content-service';

interface OrderPageDisclaimerProps {
  accessories: AccessoryGroup[];
  allTotalsCalculated: boolean;
  cartProducts: CartProduct[];
  currency: Currency;
  language: Language;
  region: Region;
  shippingAddress?: Address;
  totals: OrderTotal[];
}

/**
 * Do not introduce the zustand store to this component.
 * It is used in the receipt page, which we don't want to use the store in
 * since we have to redirect to it for some payment methods.
 */
export default function OrderPageDisclaimer({
  allTotalsCalculated,
  currency,
  shippingAddress,
  totals,
}: OrderPageDisclaimerProps): JSX.Element | null {
  const { t } = useTranslation('orderPage');

  if (!allTotalsCalculated || !shippingAddress?.country) return null;

  const country = shippingAddress.country;
  const noTaxCharged = !isTaxCharged(totals);

  const additionalTaxesMayBeApplied =
    noTaxCharged && canApplyAdditionalTaxes(country, currency);

  let disclaimerCopy: string | undefined;
  if (additionalTaxesMayBeApplied) {
    // No tax is charged now, but additional taxes or fees may be applied for delivery
    disclaimerCopy = t('additionalTaxesMayBeApplied');
  } else if (canPriceUpdateToIncludeVat(country)) {
    // Prices previously included VAT for a different country but have now been updated
    disclaimerCopy = t('priceUpdatedToReflectLocalVat');
  } else if (country === 'IN') {
    disclaimerCopy = t('dutiesWillBePaid');
  }

  if (!disclaimerCopy) return null;

  return <div className='text-inactive py-3 text-sm'>{disclaimerCopy}</div>;
}
