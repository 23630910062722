import type { AccessoryGroup } from 'services/generated/growth-content-service';
import type {
  AccessoryProduct,
  CartProduct,
  EngravingProduct,
  MembershipProduct,
} from 'ui';
import { ProductType, isEngraving } from 'ui';
import type { EngravingData } from 'ui/types/engravingData';
import type { EngravingType, EngravingInfo } from 'ui/utils/engravingUtils';
import type { EngravingParams } from 'services/generated/order-service';
import { i18n } from '@whoop/i18n/lang/client';
import { findCartProductByType } from './cartManager';

export const getEngravingGroup = (
  accessories: AccessoryGroup[],
): AccessoryGroup | undefined => {
  return accessories.find((group) => group.handle === 'engraving');
};

export const canAddEngraving = (cartProducts: CartProduct[]): boolean => {
  const cartMembership = findCartProductByType(
    cartProducts,
    ProductType.MEMBERSHIP,
  );
  if (!cartMembership) return true;
  const membership = cartMembership.item as MembershipProduct;
  return membership.membership_type !== 'trial';
};

export const hasAddedEngraving = (cartProducts: CartProduct[]): boolean => {
  return cartProducts.some((product) => isEngraving(product.item));
};

export const getDescriptionsFromEngraving = (
  engraving: EngravingInfo,
  iconTranslation: string,
): string[] => {
  if (!engraving.data) return [];
  if (engraving.type.includes('icon')) {
    return Object.values(engraving.data).map((line, index) =>
      index === 0 && iconTranslation
        ? `${line.label}: ${iconTranslation}`
        : `${line.label}: ${line.value}`,
    );
  }
  return Object.values(engraving.data).map(
    (line) => `${line.label}: ${line.value}`,
  );
};

export const buildEngravingProduct = (
  engraving: EngravingInfo,
  originalProduct: AccessoryProduct,
): EngravingProduct => {
  const iconTranslation =
    engraving.data && engraving.type.includes('icon')
      ? i18n.t(`engraving:labels.${engraving.data.icon.value}`)
      : '';
  const cartInformation = {
    ...originalProduct.cart_information,
    description: undefined,
    descriptions: getDescriptionsFromEngraving(engraving, iconTranslation),
  };
  return {
    id: originalProduct.id,
    accessory_type: 'engraving',
    product_type: ProductType.ACCESSORY,
    display_price: originalProduct.display_price,
    subtotal: originalProduct.subtotal,
    tax: originalProduct.tax ?? undefined,
    inventory_information: originalProduct.inventory_information,
    cart_information: cartInformation,
    engraving,
  };
};

export const buildEngravingProductFromParams = (
  engravingParams: EngravingParams,
  item: AccessoryProduct,
): EngravingProduct | AccessoryProduct => {
  // todo: add logic for WHOOP tag engravings when they're added to JF.
  //  Here we're assuming all engravings are for straps.
  const engravingType: EngravingType = engravingParams.icon
    ? 'gen4_strap_icon'
    : 'gen4_strap_text';
  let data: EngravingData = {};

  switch (engravingType) {
    case 'gen4_strap_icon':
      data = {
        icon: {
          label: i18n.t('engraving:icon-display'),
          value: engravingParams.icon || '',
        },
        line1: {
          label: i18n.t('engraving:line-display', { number: 1 }),
          value: engravingParams.line1 || '',
        },
      };
      break;
    case 'gen4_strap_text':
      data = {
        line1: {
          label: i18n.t('engraving:line-display', { number: 1 }),
          value: engravingParams.line1 || '',
        },
        line2: {
          label: i18n.t('engraving:line-display', { number: 2 }),
          value: engravingParams.line2 || '',
        },
        line3: {
          label: i18n.t('engraving:line-display', { number: 3 }),
          value: engravingParams.line3 || '',
        },
        line4: {
          label: i18n.t('engraving:line-display', { number: 4 }),
          value: engravingParams.line4 || '',
        },
      };
      break;
    default:
      return item;
  }

  return buildEngravingProduct({ type: engravingType, data }, item);
};

export const getEngravingParams = (
  engravingData: EngravingData,
): EngravingParams => {
  return Object.entries(engravingData).reduce((res, [key, value]) => {
    res[key] = value.value;
    return res;
  }, {});
};
