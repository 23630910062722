'use client';

import React, { useMemo } from 'react';
import clsx from 'clsx';
import type {
  Address,
  CartProduct,
  OrderTotal,
  OrderTotalType,
  ShippingOption,
} from 'ui';
import type { Currency, Language, Region } from '@whoop/i18n';
import type { AccessoryGroup } from 'services/generated/growth-content-service';
import { useTranslation } from '@whoop/i18n/lang/client';
import { formatPrice } from '@/lib/utils/formatHelpers';
import { getCartMembershipType } from '@/lib/utils/cartManager';
import { JF_SHIPPING_OPTIONS } from '@/lib/constants/experiments';
import { useExperimentService } from '@/hooks/experimentService';
import OrderPageDisclaimer from '../OrderPageDisclaimer/OrderPageDisclaimer';

export interface OrderTotalsProps extends React.HTMLAttributes<HTMLDivElement> {
  accessories: AccessoryGroup[];
  allTotalsCalculated: boolean;
  cartProducts: CartProduct[];
  currency: Currency;
  language: Language;
  region: Region;
  selectedShipping?: ShippingOption;
  shippingAddress: Address | undefined;
  totals: OrderTotal[];
}

const totalsCalculatedAtCheckout: OrderTotalType[] = [
  'shipping',
  'tax',
  'total',
];

/**
 * Do not introduce the zustand store to this component.
 * It is used in the receipt page, which we don't want to use the store in
 * since we have to redirect to it for some payment methods.
 */
export function OrderTotals({
  accessories,
  allTotalsCalculated,
  cartProducts,
  className,
  currency,
  language,
  region,
  selectedShipping,
  shippingAddress,
  totals,
  ...props
}: OrderTotalsProps): JSX.Element {
  const { getExistingVariant } = useExperimentService();
  const { t } = useTranslation('orderPage');

  const includedTaxTotal = totals.find(
    (total) => total.type === 'included_tax',
  );

  const freeShippingVariant = useMemo(() => {
    return getExistingVariant(JF_SHIPPING_OPTIONS);
  }, [cartProducts]);

  const shouldShowFreeShipping = useMemo(() => {
    return (
      (selectedShipping && selectedShipping.display_price === 0) ||
      (!selectedShipping &&
        getCartMembershipType(cartProducts) === 'prepaid' &&
        freeShippingVariant === 'promo')
    );
  }, [selectedShipping, cartProducts, freeShippingVariant]);

  const renderPrice = (totalUnknown: boolean, total: OrderTotal) => {
    if (total.type === 'shipping' && shouldShowFreeShipping) {
      return t('free');
    }
    if (totalUnknown) {
      return <span className='text-inactive'>...</span>;
    }
    return (
      <span className='text-right text-base tracking-normal'>
        {formatPrice(
          total.display_amount,
          { currency, language, region },
          {
            showCents: true,
            roundUp: false,
          },
        )}
      </span>
    );
  };

  return (
    <div className='relative mt-3 py-3'>
      <div
        className={clsx(
          'py-2 text-base font-normal tracking-normal',
          className,
        )}
        {...props}
      >
        {totals.map((total) => {
          const { label, type } = total;
          const isTotalDueTotal = type === 'total';
          const totalUnknown =
            !allTotalsCalculated && totalsCalculatedAtCheckout.includes(type);

          //Designed to instead display included_tax beneath the total due
          if (type === 'included_tax') return null;

          return (
            <div
              aria-label={label}
              className={clsx(
                'flex items-center justify-between',
                isTotalDueTotal ? 'font-semibold' : '',
              )}
              key={label}
            >
              <span>{label}</span>
              {renderPrice(totalUnknown, total)}
            </div>
          );
        })}
        {includedTaxTotal && includedTaxTotal.display_amount > 0 ? (
          <div
            aria-label={includedTaxTotal.label}
            className='text-inactive flex items-center justify-between pt-5'
            key={includedTaxTotal.label}
          >
            <span>{includedTaxTotal.label}</span>
            <span className='text-right text-base tracking-normal'>
              {formatPrice(
                includedTaxTotal.display_amount,
                { currency, language, region },
                {
                  showCents: true,
                  roundUp: false,
                },
              )}
            </span>
          </div>
        ) : null}
      </div>
      <OrderPageDisclaimer
        accessories={accessories}
        allTotalsCalculated={allTotalsCalculated}
        cartProducts={cartProducts}
        currency={currency}
        language={language}
        region={region}
        shippingAddress={shippingAddress}
        totals={totals}
      />
    </div>
  );
}
